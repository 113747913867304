body {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: .8px;
}

.App {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
}

.mapBox {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
}

.slider-wrapper {
    width: 360px;
    min-width: 360px;
    background: #fff;
    height: 100%;
    min-height: 100vh;
    transition: width .3s ease-in-out;
    overflow-y: scroll;
}

/* Hide the scrollbar */
.slider-wrapper ::-webkit-scrollbar {
    width: 0.5em;
  }
  
  .slider-wrapper ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .slider-wrapper ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
  }
  
  .slider-wrapper ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

.wrapper-hidden {
    width: 0%;
}

.key-box {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
}

.key-colors {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.key-label {
    display: flex;
    justify-content: space-between;
    font-size: .8rem;
}

.key-colors div {
    width: 100%;
    height: 20px;
    opacity: .7;
}

.key-colors > div:nth-child(1) {
    background: #00b050;
}
.key-colors > div:nth-child(2) {
    background: #ffff00;
}
.key-colors > div:nth-child(3) {
    background: #ffa800;
}
.key-colors > div:nth-child(4) {
    background: #ea2222;
}

.flipped {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }


.hidden {
    display: none;
}

.slider-container {
    width: 100%;
}

.slider-container h6 {
    margin: 10px 0px 0px 0px;
}

input[type=range] {
    height: 23px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #0047AB;
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 3px solid #0047AB;
    height: 14px;
    width: 41px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #0047AB;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #0047AB;
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 3px solid #0047AB;
    height: 14px;
    width: 41px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #0047AB;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #0047AB;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 3px solid #0047AB;
    height: 14px;
    width: 41px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #0047AB;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #0047AB;
  }
   
.leaflet-control-layers-toggle { display: none !important;}

.spacer {
    padding: 15px 25px;
}

.data-box {
    font-size: .7rem;
    text-align: right;
    margin-bottom: 35px;
}

.data-box a {
    text-decoration: none;
}

.map-container {
    height: 100%;
    width: 100%;
}

.toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0px;
    background-color: #ececec;
    border: 3px solid #ececec;
    border-radius: 5px;
}

.toggle-box {
    margin: 10px 0px;
    border-bottom: 2px solid #ececec;
}

.toggle-name {
    margin-left: 5px;
}

.disclosure {
    margin: 10px 0px;
}

.selected {
    border-color: #0047AB;
}

.title-box {
    background: #0047AB;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto Slab', serif;
    letter-spacing: 1px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #0047AB;
  }

  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
.slider.round:before {
    border-radius: 50%;
}

.profile-item {
    display: flex;
    justify-content: space-between;
}

.profile-item span {
    min-width: 30px;
    text-align: right;
}

.sub-text {
    color: #0047AB;
    margin: 15px 0px;
    font-style: italic;
}

.profile-item div {
    display: flex;
    align-items: center;
}

.profile-item div h5 {
    margin-left: 10px;
}

.profile h3 {
    color: #0047AB;
    margin-bottom: 15px;
}

hr {
    display: block;
    margin: 10px 0px;
    border-style: dotted none none;
    border-width: 4px;
    border-color: #ccc;
}

.region-label {
  font-size: 10px;
  color: #fff !important;
  font-weight: bold;
  background-color: #0047AB !important;
  padding: 2px 5px !important;
  border-radius: 3px !important;
  border: 0px !important;
  margin: 0px auto !important;
  box-shadow: none !important;
  display: none;
  text-align: center !important;
}
